<template>
  <div class="temporaryLeave">
    <el-radio-group v-model="tableType"
                    @change="loadTemporaryLeave(1)">
      <el-radio-button :label="1">申请</el-radio-button>
      <el-radio-button :label="2">审核</el-radio-button>
    </el-radio-group>
    <div class="header-query">
      <div>
        <el-button type="success"
                   v-if="tableType===1"
                   @click="addTemporaryLeaveClick">临时假申请</el-button>
        <!-- <el-button type="info">导出</el-button> -->
      </div>
      <div>
        <el-date-picker v-model="queryTime"
                        type="daterange"
                        class="picker"
                        range-separator="至"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <el-select placeholder="请选择状态"
                   class="picker"
                   v-model="queryStatus">
          <el-option v-for="status in statusList"
                     :key="status.value"
                     :label="status.name"
                     :value="status.value"></el-option>
        </el-select>
        <el-button type="primary"
                   @click="loadTemporaryLeave(1)">查询</el-button>
      </div>
    </div>
    <el-table :data="temporaryLeaveTable">
      <el-table-column align="center"
                       prop="typeStr"
                       label="请假类型"></el-table-column>
      <el-table-column align="center"
                       prop="begin_time"
                       label="开始时间"></el-table-column>
      <el-table-column align="center"
                       prop="end_time"
                       label="结束时间"></el-table-column>
      <el-table-column align="center"
                       prop="duration"
                       label="时长"></el-table-column>
      <el-table-column align="center"
                       prop="reviewer"
                       label="审核人"></el-table-column>
      <el-table-column align="center"
                       prop="statusStr"
                       label="状态"></el-table-column>
      <el-table-column align="center"
                       prop="opinion"
                       label="审核意见"></el-table-column>
      <el-table-column align="center"
                       :width="tableType===2?'100px':'180px'"
                       label="操作">
        <template slot-scope="scope">
          <div v-if="tableType===1">
            <!-- <el-button type="primary"
                       size="mini"
                       @click="editTemporaryLeave(scope.row,1)">编 辑</el-button> -->
            <el-button type="warning"
                       @click="editTemporaryLeave(scope.row,2)">详 情</el-button>
            <el-button type="info"
                       :disabled="scope.row.status!==0"
                       @click="withdrawTemporaryLeave(scope.row.id)">撤 回</el-button>
          </div>
          <!-- auditTemporaryLeave -->
          <div v-if="tableType===2">
            <el-button type="primary"
                       :disabled="scope.row.status!==0"
                       @click="editTemporaryLeave(scope.row,3)">审 核</el-button>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="temporaryLeaveVisible"
               :title="temporaryLeaveForm.id?'查看申请':'申请临时假'"
               width="600px">
      <el-form :model="temporaryLeaveForm"
               ref="temporaryLeaveForm"
               class="temporaryLeaveForm"
               label-width="120px"
               :rules="temporaryLeaveFormRules">
        <el-form-item label="开始时间"
                      prop="startTime">
          <el-date-picker v-model="temporaryLeaveForm.startTime"
                          type="datetime"
                          :disabled="disable"
                          placeholder="开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间"
                      prop="endTime">
          <el-date-picker v-model="temporaryLeaveForm.endTime"
                          type="datetime"
                          @change="endTimeChange"
                          :disabled="disable"
                          placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="请假类型"
                      prop="leaveType">
          <el-select v-model="temporaryLeaveForm.leaveType"
                     :disabled="disable"
                     placeholder="请选择类型">
            <el-option v-for="type in leaveTypeList"
                       :key="type.value"
                       :label="type.name"
                       :value="type.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注"
                      prop="remark">
          <el-input type="textarea"
                    :disabled="disable"
                    v-model="temporaryLeaveForm.remark"
                    placeholder="请输入请假原因"></el-input>
        </el-form-item>
        <el-form-item label="时长"
                      disabled
                      prop="duration">
          <el-input v-model="temporaryLeaveForm.duration"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="是否教师"
                      prop="isTeacher">
          <el-switch v-model="temporaryLeaveForm.isTeacher"
                     :disabled="disable"
                     active-text="是"
                     inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="审批人"
                      prop="approver">
          <el-select v-model="temporaryLeaveForm.approver"
                     :disabled="disable">
            <el-option v-for="approver in approveList"
                       :label="approver.mname"
                       :value="approver.managerid"
                       :key="approver.managerid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="附件">
          <el-upload action="/api/base/uploadByOss.do"
                     :headers="{Authorization:token}"
                     :limit="1"
                     v-if="!disable"
                     :on-remove="onRemoveFile"
                     :on-success="enclosureUploadSuccess"
                     :data="{path:'temporaryLeave',name:''}"
                     :show-file-list="true">
            <el-button type="primary"
                       icon='el-icon-upload2'>导 入</el-button>
          </el-upload>
          <el-button type="primary"
                     class="load-file"
                     @click="loadFile"
                     v-if="temporaryLeaveForm.fileUrl">下载附件</el-button>
        </el-form-item>
        <!-- 意见 -->
        <div v-if="disable">
          <hr>
          <el-form-item label="意见">
            <el-input type="textarea"
                      :disabled="tableType===1"
                      v-model="temporaryLeaveForm.fbcontent"
                      placeholder="请输入审核意见"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer">
        <div v-if="tableType===1">
          <el-button @click="temporaryLeaveVisible=false">取 消</el-button>
          <el-button type="primary"
                     v-if="!disable"
                     @click="temporaryLeaveConfirm">确 认</el-button>
        </div>
        <div v-if="tableType===2">
          <el-button type="primary"
                     @click="auditTemporaryLeave(1)">同 意</el-button>
          <el-button type="primary"
                     @click="auditTemporaryLeave(2)">拒 绝</el-button>
        </div>
      </div>
    </el-dialog>
    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadTemporaryLeave"></el-pagination>
  </div>
</template>

<script>
export default {
  name: "temporaryLeave",
  data() {
    return {
      page: { currentPage: 1, pageCount: 0 },
      tableType: 1,
      temporaryLeaveTable: [],
      temporaryLeaveVisible: false,
      temporaryLeaveForm: {
        startTime: "",
        endTime: "",
        leaveType: "",
        remark: "",
        duration: "",
        isTeacher: true,
        approver: "",
        fileUrl: "",
        fbcontent: "",
      },
      queryTime: "",
      queryStatus: "",
      statusList: [
        { name: "发布成功", value: 0 },
        { name: "通过", value: 1 },
        { name: "拒绝", value: 2 },
        { name: "已撤回", value: 3 },
        { name: "全部", value: "" },
      ],
      leaveTypeList: [
        { name: "晚到", value: 1 },
        { name: "外出", value: 2 },
        { name: "其他", value: 3 },
      ],
      temporaryLeaveFormRules: {
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
        ],
        leaveType: [
          { required: true, message: "请选择请假类型", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
        duration: [{ required: true, message: "请选择时长", trigger: "blur" }],
        isTeacher: [{ required: true }],
        approver: [{ required: true }],
      },
      approveList: [],
      //   buttonType: 0, //0申请，1编辑，2详情，3审核
      disable: false, //表单是否可编辑
      token: sessionStorage.getItem("token"),
    };
  },
  created() {
    this.loadTemporaryLeave(1);
    this.loadApprover();
  },
  methods: {
    loadTemporaryLeave(page) {
      let apiUrl = "/base/queryLeaveTemporaryByTea.do";
      let data = {
        indexNo: page,
        status: this.queryStatus,
      };
      if (this.queryTime) {
        data.begin_time = this.queryTime[0];
        data.end_time = this.queryTime[1];
      }
      if (this.tableType === 2) {
        apiUrl = "/base/queryLeaveTemporaryByGl.do";
      }
      this.$post(apiUrl, data)
        .then((res) => {
          this.temporaryLeaveTable = res.data.rows;
          this.page.currentPage = res.data.indexNo;
          this.page.pageCount = res.data.pageCount;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    addTemporaryLeaveClick() {
      this.disable = false;
      this.temporaryLeaveVisible = true;
      this.$nextTick(() => {
        this.$refs["temporaryLeaveForm"].resetFields();
        this.temporaryLeaveForm.id = "";
      });
    },
    loadApprover() {
      this.$post("/base/queryApprover.do", { type: 7 }) //7查询临时假的审批人
        .then((res) => {
          this.approveList = res.data;
          this.temporaryLeaveForm.approver = res.data[0].managerid;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 时间转换
    getTime(time) {
      let d = new Date(time);
      let year = d.getFullYear();
      let month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      let date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      let hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      let minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      return `${year}-${month}-${date} ${hour}:${minutes}`;
    },
    temporaryLeaveConfirm() {
      this.$refs["temporaryLeaveForm"].validate((valid) => {
        if (valid) {
          //   if (this.temporaryLeaveForm.duration) {
          //   }
          this.$confirm("是否确认提交该申请？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post("/base/insertLeaveTemporary.do", {
                begin_time: this.getTime(this.temporaryLeaveForm.startTime),
                end_time: this.getTime(this.temporaryLeaveForm.endTime),
                type: this.temporaryLeaveForm.leaveType,
                remark: this.temporaryLeaveForm.remark,
                duration: this.temporaryLeaveForm.duration,
                reviewer_id: this.temporaryLeaveForm.approver,
                is_tea: this.temporaryLeaveForm.isTeacher ? 2 : 1,
                url: this.temporaryLeaveForm.fileUrl,
              })
                .then((res) => {
                  this.temporaryLeaveVisible = false;
                  this.loadTemporaryLeave(1);
                  this.$message({ type: "success", message: res.message });
                })
                .catch((err) => {
                  this.$message({ type: "warning", message: err.message });
                });
            })
            .catch(() => {
              this.$message({ type: "warning", message: "已取消确认" });
            });
        }
      });
    },
    editTemporaryLeave(row, buttonType) {
      this.temporaryLeaveVisible = true;
      this.$nextTick(() => {
        this.temporaryLeaveForm.startTime = row.begin_time;
        this.temporaryLeaveForm.endTime = row.end_time;
        this.temporaryLeaveForm.leaveType = row.type;
        this.temporaryLeaveForm.remark = row.remark;
        this.temporaryLeaveForm.duration = row.duration;
        this.temporaryLeaveForm.isTeacher = row.is_tea === 2;
        this.temporaryLeaveForm.approver = row.reviewer_id;
        this.temporaryLeaveForm.fileUrl = row.url;
        this.temporaryLeaveForm.fbcontent = row.opinion;
        this.temporaryLeaveForm.id = row.id;
      });
      if (buttonType === 2 || buttonType === 3) {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    // 撤回
    withdrawTemporaryLeave(id) {
      this.$confirm("是否确认撤回该数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/cancelLeaveTemporary.do", { id })
            .then((res) => {
              this.loadTemporaryLeave(1);
              this.$message({ type: "success", message: res.message });
            })
            .catch((err) => {
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消撤回" });
        });
    },
    auditTemporaryLeave(status) {
      this.$confirm("是否确认该操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/updateLeaveTemporaryStatus.do", {
            id: this.temporaryLeaveForm.id,
            status,
            opinion: this.temporaryLeaveForm.fbcontent,
          })
            .then((res) => {
              this.loadTemporaryLeave(1);
              this.temporaryLeaveVisible = false;
              this.$message({ type: "success", message: res.message });
            })
            .catch((err) => {
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消操作" });
        });
    },
    // 选择结束时间出现时长
    endTimeChange() {
      let currentStartTime = this.temporaryLeaveForm.startTime.getTime();
      let currentEndTime = this.temporaryLeaveForm.endTime.getTime();
      let time = currentEndTime - currentStartTime;
      let hours = parseInt((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = parseInt((time % (1000 * 60 * 60)) / (1000 * 60));
      let currentDuration = parseInt(time / 1000 / 60);
      console.log(currentDuration);
      this.temporaryLeaveForm.duration = `${hours}小时${minutes}分钟`;
      if (currentDuration > 180) {
        this.temporaryLeaveForm.duration = "";
        this.$message({
          type: "warning",
          message: "请假不得超过三个小时",
        });
        return;
      }
    },
    // 文件上传成功的钩子
    enclosureUploadSuccess(res, file) {
      this.temporaryLeaveForm.fileUrl = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.temporaryLeaveForm.fileUrl);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.temporaryLeaveForm.fileUrl = "";
    },
  },
};
</script>

<style scoped lang="scss">
.header-query {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.picker {
  margin: 0 5px;
}
.temporaryLeaveForm {
  height: 500px;
  overflow-x: auto;
}
</style>
